.catland {
  margin: 2rem;
  text-align: center;
}

.catland .header {
  text-align: center;
}

.catland .header img {
  width: auto;
}

.catland h1 {
  width: 60%;
  margin: 0 auto;
  padding-top: 1rem;
  padding-bottom: 1rem;
  text-align: center;
  font-size: 2rem;
  font-weight: bold;
}

.catland p {
  width: 60%;
  margin: 0 auto;
}

.catland img {
  margin: 0 auto;
  width: 100%;
}

.nav-menu {
  list-style: none;
  text-align: center;
}

.nav-menu li {
  display: inline;
  font-size: 4rem;
  margin-left: 1rem;
  margin-right: 1rem;
}

.nav-menu li a {
  text-decoration: none;
}